html, body, main, div, h3, p, a, input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

html {
    font-size: 16px;
}

@media only screen and (max-device-width : 580px) {
    html {
        font-size: 80%;
    }
}

@media only screen and (max-device-width : 470px) {
    html {
        font-size: 60%;
    }
}

p {
    font-size: 16px;
}

a, a:link, a:visited, a:hover, a:active {
    color: #222;
}

.wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    min-height: 100%;
    color: #222;
    font-family: 'Lato', Arial, sans-serif;
    font-weight: 300;
    background-color: #44B687;
    transition: background-color 1s;
    padding: 0 5%;
}

.card {
    position: relative;
    margin: 5rem 0;
    width: 100%;
    max-width: 32.5rem;
}

form {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 1.25rem 1.25rem 1.25rem;
    border-bottom: 5px solid #222;
    z-index: 2;
}

.user-input {
    width: 100%;
    background: none;
    border: 0;
    color: inherit;
    font-family: inherit;
    font-weight: 900;
    font-size: 3rem;
}

.user-input::placeholder {
    color: inherit;
}

.user-input:disabled {
    color: #222;
}

.submit-button {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-left: 0.94rem;
    border: 5px solid #222;
    border-radius: 50%;
    background: none;
    cursor: pointer;
}

.submit-button span {
    display: block;
    position: relative;
    width: 20px;
    height: 5px;
    background-color: #222;
}

.submit-button span:first-child {
    top: -6.5%;
    transform: rotate(45deg);
}

.submit-button span:last-child {
    top: 6.5%;
    transform: rotate(-45deg);
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: auto;
}

.submit-button:disabled span {
    width: 30px;
}

.submit-button:disabled span:first-child {
    top: 5%;
}

.submit-button:disabled span:last-child {
    top: -5%;
}

@keyframes loader {
    to {transform: rotate(360deg);}
}

.loader {
    flex-shrink: 0;
    content: '';
    position: relative;
    margin-left: 0.94rem;
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #222;
    animation: loader .6s linear infinite;
}

.autosuggest {
    position: relative;
    max-height: 30rem;
    overflow: scroll;
}

.search-suggestion {
    margin-top: 1.5rem;
    padding: 1.25rem;
    border: 1px solid #222;
}

.search-suggestion-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.animation-hider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(6.25rem + 60px + 5px);
    transition: background-color 1s;
    z-index: 1;
}

.animation-wrapper {
    position: relative;
    width: 100%;
    transition: transform 0.5s;
    overflow: auto;
}

.results-wrapper {
    padding: 3.75rem 1.25rem 0 1.25rem;
}

.error-icon {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    border: 5px solid #222;
    border-radius: 50%;
    background: none;
}

.exclamation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 7px;
    background-color: #222;
}

.exclamation-top {
    top: 20%;
    height: 23px;
}

.exclamation-bottom {
    bottom: 20%;
    height: 7px;
}

.error-message {
    margin-top: 1.5rem;
    text-align: center;
}

.conservation-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 3.75rem;
}

.conservation-circle {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.31rem solid #222;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 2.5rem;
    font-weight: 900;
}

.conservation-status {
    margin-top: 2.5rem;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
}
